import { useReducer, createContext, useMemo } from 'react';
import _ from 'lodash';

const initialState = {
    userId: 'DC',
    isShop: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'setIsShop':
            return {
                ...state,
                isShop: action.payload,
                userId: action.payload ? '1571' : 'DC'
            }
        default:
            return state;
    }
}

const Context = createContext();

export const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const contextValue = useMemo(() => {
        return [state, dispatch];
    }, [state, dispatch]);

    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    );

}

export default Context;