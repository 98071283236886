import React, { useContext, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import OperationsContext from '../contexts/OperationsContext';
import UserContext from '../contexts/UserContext';

export default function OperationsList() {
  const [{ entries }, dispatch] = useContext(OperationsContext);
  const [{ isShop, userId }] = useContext(UserContext);

  useEffect(() => {
    dispatch({
      type: 'getOperations',
      payload: { userId, useFilter: isShop }
    })
  }, [isShop, userId])

  const handleFormOpen = () => {
    dispatch({
      type: 'setIsFormOpen',
      payload: true
    });
  };

  const handleConfirm = (id) => {
    dispatch({
      type: 'setIsFormOpen',
      payload: true
    });
    dispatch({
      type: 'setEditedOperationsById',
      payload: id
    });
  }

  return (
    <>
      <Button variant="contained" style={{ marginBottom: "20px" }} color="primary" onClick={handleFormOpen} startIcon={<AddIcon />}>Dodaj trasę</Button>
      <TableContainer component={Paper}>
        <Table size='small' sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Okres</TableCell>
              <TableCell align="center">Trasa</TableCell>
              <TableCell align="center">Przewoźnik</TableCell>
              <TableCell align="center">Data załadunku</TableCell>
              <TableCell align="center">Nadawca</TableCell>
              <TableCell align="center">Odbiorca</TableCell>
              <TableCell align="center">Rozpatrzone</TableCell>
              <TableCell align="center">Zgadza się</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(o => (
              <TableRow
                key={o.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{o.dateOfLoading.slice(3)}</TableCell>
                <TableCell align="center">{o.trackId}</TableCell>
                <TableCell align="center">{o.carrier}</TableCell>
                <TableCell align="center">{o.dateOfLoading}</TableCell>
                <TableCell align="center">{o.sender}</TableCell>
                <TableCell align="center">{o.recipient}</TableCell>
                <TableCell align="center">{o.confirmed ? 'TAK' : ''}</TableCell>
                <TableCell align="center">{o.confirmed && (o.match ? 'TAK' : 'NIE')}</TableCell>
                <TableCell align="right"><Button onClick={() => handleConfirm(o.id)}>{isShop ? 'Przyjęcie' : 'Edytuj'}</Button></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}