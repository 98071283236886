import { useReducer, createContext, useMemo } from 'react';
import _ from 'lodash';
import { operations } from '../db';

const initialState = {
    carriers: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
    entries: _.sortBy(operations, ['id'], ['desc']).reverse(),
    operationTemplate: {
        trackId: '',
        dateOfLoading: '',
        sender: '',
        recipient: '',
        carrier: '',
        carriers: { //todo nazwa
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['', '', '',],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    },
    editedOperations: [],
    isFormOpen: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'getOperations': {
            const { userId, useFilter } = action.payload;
            return {
                ...state,
                entries: _.chain(operations)
                    .filter(i => {
                        if (useFilter === false) {
                            return i;
                        }

                        if (i.sender === userId || i.recipient === userId) {
                            return i;
                        }
                    })
                    .sortBy(['id'], ['desc'])
                    .reverse()
                    .value()
            }
        }
        case 'setIsFormOpen':
            return {
                ...state,
                isFormOpen: action.payload,
                editedOperations: []
            }
        case 'addEditedOperation':
            return {
                ...state,
                editedOperations: [
                    ...state.editedOperations,
                    _.cloneDeep(state.operationTemplate)
                ]
            }
        case 'setEditedOperationsById':
            return {
                ...state,
                editedOperations: [
                    _.cloneDeep(_.find(operations, { id: action.payload }))
                ]
            }
        case 'saveOperations': {
            [...state.editedOperations].forEach(o => {
                if (!o.id) {
                    o.id = Date.now();
                    operations.push(o);
                } else {
                    const { sender, recipient } = o.carriers.values;
                    const newOperation = _.cloneDeep(o);
                    newOperation.confirmed = true;
                    newOperation.match = _.isEqual(sender, recipient);
                    operations[_.findIndex(operations, { id: o.id })] = newOperation;
                }
            })

            return state;
        }
        case 'operationDataChange': {
            const newValue = [
                ...state.editedOperations
            ];

            _.set(newValue[action.payload.index], action.payload.property, action.payload.value);

            return {
                ...state,
                editedOperations: newValue
            }
        }
        default:
            return state;
    }
}

const Context = createContext();

export const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const contextValue = useMemo(() => {
        return [state, dispatch];
    }, [state, dispatch]);

    return (
        <Context.Provider value={contextValue}>
            {children}
        </Context.Provider>
    );

}

export default Context;