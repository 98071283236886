import React, { useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';

import CrossDockContext from '../contexts/CrossDockContext';
import UserContext from '../contexts/UserContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Field = ({ children, sx, other }) => {
  return <Box sx={{
    m: 1,
    ...sx
  }} {...other}>{children}</Box>
}

const renderOperationData = ({ index, handleOpeartionChange, types, values, valueType }) => {
  return types.map((type, i) => (
    <TextField
      key={`operation_data_${i}`}
      value={values[valueType][i]}
      margin="dense"
      id={type}
      type="number"
      label={type}
      variant="outlined"
      size="small"
      fullWidth
      InputLabelProps={{
        shrink: true
      }}
      onChange={e => handleOpeartionChange(index, `carriers.values.${valueType}[${i}]`, e.target.value)}
    />)
  );
}

const renderOperations = ({ operations, handleRemoveOperation, handleOpeartionChange, userId, isShop }) => {
  return operations.map((operation, i) => (
    <Card key={`operation_${i}`} sx={{
      mt: 2
    }}>
      <CardContent>
        <Box sx={{
          display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'
        }}>
          <Field>
            <TextField
              required
              autoFocus
              margin="dense"
              id="trackId"
              label="Trasa"
              type="text"
              fullWidth
              variant="standard"
              size="small"
              value={operation.trackId}
              onChange={e => handleOpeartionChange(i, 'trackId', e.target.value)}
            />
          </Field>
          <Field>
            <TextField
              required
              margin="dense"
              id="carrier"
              label="Przewoźnik"
              type="text"
              fullWidth
              variant="standard"
              size="small"
              value={operation.carrier}
              onChange={e => handleOpeartionChange(i, 'carrier', e.target.value)}
            />
          </Field>
        </Box>
        <Field>
          <TextField
            required
            margin="dense"
            id="dateOfLoading"
            label="Data załadunku"
            placeholder='DD.MM.RRRR'
            type="text"
            fullWidth
            variant="standard"
            size="small"
            value={operation.dateOfLoading}
            onChange={e => handleOpeartionChange(i, 'dateOfLoading', e.target.value)}
          />
        </Field>
        <Box sx={{
          display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'
        }}>
          <Field>
            <TextField
              required
              margin="dense"
              id="sender"
              label="Nadawca"
              type="text"
              fullWidth
              variant="standard"
              size="small"
              value={operation.sender}
              onChange={e => handleOpeartionChange(i, 'sender', e.target.value)}
            />
            <Typography>Nadanie</Typography>
            {renderOperationData({
              index: i,
              handleOpeartionChange,
              types: operation.carriers.types,
              values: operation.carriers.values,
              valueType: 'sender',
            })}
          </Field>
          <Field>
            <TextField
              required
              margin="dense"
              id="recipient"
              label="Odbiorca"
              type="text"
              fullWidth
              variant="standard"
              size="small"
              value={operation.recipient}
              onChange={e => handleOpeartionChange(i, 'recipient', e.target.value)}
            />
            <Box sx={{
              display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)'
            }}>
              <Field>
                <Typography>Przyjęcie</Typography>
                {renderOperationData({
                  index: i,
                  handleOpeartionChange,
                  types: operation.carriers.types,
                  values: operation.carriers.values,
                  valueType: 'recipient_accepted'
                })}
              </Field>
              <Field>
                <Typography>Zwrot</Typography>
                {renderOperationData({
                  index: i,
                  handleOpeartionChange,
                  types: operation.carriers.types,
                  values: operation.carriers.values,
                  valueType: 'recipient_returned'
                })}
              </Field>
            </Box>
          </Field>
        </Box>
      </CardContent>
    </Card>
  ));
}

export default function OperationsFormDialog() {
  const [{ editedOperations }, dispatch] = useContext(CrossDockContext);
  const [{ isShop, userId }] = useContext(UserContext);

  const handleClose = () => {
    dispatch({
      type: 'setIsFormOpen',
      payload: false
    })
  };

  const handleSave = () => {
    dispatch({
      type: 'saveOperations'
    })
    dispatch({
      type: 'getOperations',
      payload: { userId, useFilter: isShop }
    })
    dispatch({
      type: 'setIsFormOpen',
      payload: false
    })
  }

  const handleRemoveOperation = (index) => {
    dispatch({
      type: 'removeEditedOperation',
      payload: index
    })
  }

  const handleOpeartionChange = (index, property, value) => {
    dispatch({
      type: 'operationDataChange',
      payload: {
        index,
        property,
        value
      }
    })
  }

  useEffect(() => {
    if (editedOperations.length === 0) {
      dispatch({
        type: 'addEditedOperation'
      })
    }
  }, [])

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Przyjęcie
          </Typography>
          <Button onClick={handleClose} color="inherit">Anuluj</Button>
          <Button onClick={handleSave} color="inherit">Zapisz</Button>
        </Toolbar>
      </AppBar>
      <DialogContent component={Paper}>
        {renderOperations({ operations: editedOperations, handleRemoveOperation, handleOpeartionChange, isShop, userId })}
      </DialogContent>
    </Dialog>
  );
}