import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import FunctionsIcon from '@mui/icons-material/Functions';
import SettingsIcon from '@mui/icons-material/Settings';

import UserContext from '../contexts/UserContext';

export default function MenuAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [{ isShop, userId }, dispatch] = useContext(UserContext);

  const setShop = (shop) => {
    dispatch({
      type: 'setIsShop',
      payload: !!shop
    })
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <BlurOnIcon /> CastoAppka
          </Typography>
          <div>
            <Button style={{ marginRight: '20px' }} variant="contained" color="primary" startIcon={<SettingsIcon />}>USTAWIENIA</Button>
            <Button style={{ marginRight: '20px' }} variant="contained" color="primary" startIcon={<FunctionsIcon />}>SALDA</Button>
            <Button style={{ marginRight: '20px' }} variant="contained" color="primary" startIcon={<SummarizeIcon />}>RAPORTY</Button>
            <Button color="inherit">{isShop ? `Sklep ${userId}` : 'Magazyn'}</Button>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => setShop()}>Magazyn</MenuItem>
              <MenuItem onClick={() => setShop(true)}>Sklep</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
    </Box>
  );
}
