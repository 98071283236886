import React, { useContext } from 'react';
import OperationsList from '../components/OperationsList';
import OperationsFormDialog from '../components/OperationsFormDialog';
import OperationsContext from '../contexts/OperationsContext';

export default function OperationsContainer() {
  const [{ isFormOpen }] = useContext(OperationsContext);

  return (
    <>
      <OperationsList />
      { isFormOpen && <OperationsFormDialog />}
    </>
  );
}