const operations = [
    {
        id: 1,
        trackId: 'SGR2201000979',
        dateOfLoading: '06.02.2022',
        sender: 'PEKAES',
        recipient: '1571',
        carrier: 'PEKAES',
        carriers: {
            types: ['Eur', 'JED', 'CHEP / inne'],
            values: {
                sender: ['1', '2', ''],
                recipient_accepted: ['', '', ''],
                recipient_returned: ['', '', '']
            }
        }
    }
];

export {
    operations
}