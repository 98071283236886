import CssBaseline from '@mui/material/CssBaseline';
import MainLayout from './layouts/MainLayout';
import { Provider as UserProvider } from './contexts/UserContext';

export default function App() {
  return (
    <UserProvider>
      <CssBaseline />
      <MainLayout />
    </UserProvider>
  );
}