const operations = [
    {
        id: 1,
        trackId: 'SGR2201000979',
        dateOfLoading: '25.01.2022',
        sender: 'DC1',
        recipient: '1571',
        carrier: 'PEKAES',
        carriers: {
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['2', '12', ''],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    },
    {
        id: 2,
        trackId: 'SGR5501030979',
        dateOfLoading: '25.01.2022',
        sender: 'DC2',
        recipient: '1572',
        carrier: '',
        carriers: { //todo nazwa
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['', '', ''],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    },
    {
        id: 3,
        trackId: 'SGR5501030979',
        dateOfLoading: '25.01.2022',
        sender: 'DC3',
        recipient: '1573',
        carrier: '',
        carriers: { //todo nazwa
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['', '', ''],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    },
    {
        id: 4,
        trackId: 'SGR5501030979',
        dateOfLoading: '25.01.2022',
        sender: 'DC3',
        recipient: '1574',
        carrier: '',
        carriers: { //todo nazwa
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['', '', ''],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    },
    {
        id: 5,
        trackId: 'SGR5501030979',
        dateOfLoading: '25.01.2022',
        sender: 'DC3',
        recipient: '1575',

        carrier: '',
        carriers: { //todo nazwa
            types: ['Jumbo', 'MiniJumbo', 'Rollkontener'],
            values: {
                sender: ['', '', ''],
                recipient: ['', '', '']
            }
        },
        confirmed: false,
        match: false
    }
];

export {
    operations
}