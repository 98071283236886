import React, { useContext } from 'react';
import CrossDockList from '../components/CrossDockList';
import CrossDockFormDialog from '../components/CrossDockFormDialog';
import CrossDockContext from '../contexts/CrossDockContext';

export default function CrossDockContainer() {
  const [{ isFormOpen }] = useContext(CrossDockContext);

  return (
    <>
      <CrossDockList />
      {isFormOpen && <CrossDockFormDialog />}
    </>
  );
}